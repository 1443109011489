export const imageValidator = (src, ratioRequired = false, propsRatio = '1:2') => new Promise(
  (resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      function getRatio(a, b) { return (b === 0) ? a : getRatio(b, a % b); }
      const ratio = getRatio(img.width, img.height);
      const aspectRatio = `${img.height / ratio}:${img.width / ratio}`;
      if (aspectRatio === propsRatio || !ratioRequired) {
        resolve(true);
      }
      reject(Error('error'));
    };
    img.src = src;
  },
);
