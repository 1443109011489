<template>
  <VForm
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <p>
      <span class="red--text">*</span>
      <span>Все поля обязательны для заполнения</span>
    </p>
    <VRow>
      <VCol class="mt-3" cols="2">
        <span>Заведение</span>
        <span class="red--text">*</span>
      </VCol>
      <VCol cols="8">
        <RestaurantSelection
          outline-color="#4D4F67"
          required
          :restaurant="promotionToEdit.restaurant"
          @restaurantSelected="promotionToEdit.restaurant = $event"
        />
      </VCol>
    </VRow>
    <span v-if="promotionToEdit.restaurant">
      <VRow>
        <VCol class="label pr-0" cols="2">
          <div>
            <span>Название на главной</span>
            <span class="red--text">*</span>
          </div>
          <div class="text-caption">Название показывается на <br> главной приложения</div>
        </VCol>
        <VCol class="d-flex" cols="8">
          <VTextarea
            v-model="promotionToEdit.header"
            auto-grow
            class="pr-2 promo-header-ru"
            dense
            outlined
            placeholder="Название на русском"
            row-height="24"
            rows="1"
            :rules="[rules.required]"
          />
          <VTextarea
            v-model="promotionToEdit.headerKz"
            auto-grow
            class="pl-2 promo-header-kz"
            dense
            outlined
            placeholder="Название на казахском"
            row-height="24"
            rows="1"
            :rules="[rules.required]"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol class="label pr-0" cols="2">
          <div>
            <span>Название в ресторане</span>
            <span class="red--text">*</span>
          </div>
          <div class="text-caption">Название показывается на <br> странице ресторана</div>
        </VCol>
        <VCol cols="8">
          <div class="d-flex">
            <VTextField
              v-model="promotionToEdit.headerShort"
              class="pr-2 w-50"
              dense
              outlined
              placeholder="Название на русском"
              :rules="[rules.required]"
            />
            <VTextField
              v-model="promotionToEdit.headerShortKz"
              class="pl-2 w-50"
              dense
              outlined
              placeholder="Название на казахском"
              :rules="[rules.required]"
            />
          </div>
          <p class="text-caption">
            Пример: “-30% на все пиццы”, “2+1”, “Чизкейк в подарок от 10 000тг”
          </p>
        </VCol>
      </VRow>
      <VRow class="pr-0">
        <VCol class="mt-3" cols="2">
          <span>Изображение</span>
          <span class="red--text">*</span>
        </VCol>
        <VCol class="pb-0" cols="8">
          <ImageUpload
            allow-preview
            class="image-box cover"
            :contains-title="false"
            dense
            description="Скопируйте ссылку на месторасположение категории,
                        либо загрузите изображение в формате PNG, JPEG размером 1600x1200px"
            disable-padding
            :image="promotionToEdit.image"
            image-down
            image-type="restaurant"
            left-col="0"
            required
            @update="promotionToEdit.image = $event.logo"
          />
        </VCol>
      </VRow>
      <VRow>
        <VCol class="mt-3" cols="2">
          <span>Описание</span>
          <span class="red--text">*</span>
        </VCol>
        <VCol cols="8">
          <div class="d-flex justify-space-between">
            <VTextarea
              v-model.trim="promotionToEdit.description"
              class="pr-2 w-50 promo-description-ru"
              outlined
              placeholder="Введите описание акции на русском"
              :rules="[rules.required, getMaxRule(240)]"
            />
            <VTextarea
              v-model.trim="promotionToEdit.descriptionKz"
              class="pl-2 w-50 promo-description-kz"
              outlined
              placeholder="Введите описание акции на казахском"
              :rules="[rules.required, getMaxRule(240)]"
            />
          </div>
          <p class="text-caption">
            Ограниченное количество текста для описания - максимум 240 символов. <br>
            Пример: При заказе на сумму от 10.000тг и выше - Испанский чизкейк в подарок.
            Подарок вы можете найти прямо в корзине. Акция действует до конца июня
          </p>
        </VCol>
      </VRow>
      <span>
        <VRow>
          <VCol class="mt-2" cols="2">
            <span>Тип акции</span>
            <span class="red--text">*</span>
          </VCol>
          <VCol cols="6">
            <div class="d-flex flex-column">
              <VChipGroup
                v-model="promotionToEdit.typeName"
                active-class="type-selected"
                class="mb-1"
                mandatory
              >
                <VChip
                  v-for="key in Object.keys(promotionTypes)"
                  :key="key"
                  class="justify-center chip-btn"
                  :value="key"
                >
                  <span class="ma-5">{{ promotionTypes[key] }}</span>
                </VChip>
              </VChipGroup>
              <span class="text-caption">
                Выберите тип акции. <br>
                Скидка -  тип акции, при котором к блюду / блюдам / всему меню заведения
                будет применен дисконт. <br>
                Информационная - тип акции, которая проинформирует пользователей о проходящей
                активности - конкурсе, розыгрыше призов и т.п.
              </span>
            </div>
            <div v-if="promotionToEdit.typeName === 'discount'">
              <div class="d-flex align-center">
                <span>Размер скидки</span>
                <span class="red--text mr-5">*</span>
                <VTextField
                  v-model="promotionToEdit.promotionType.amount"
                  class="w-35 mr-3 discount-amount mt-6"
                  dense
                  outlined
                  placeholder="Ведите значение"
                  :rules="[rules.required]"
                />
                <VChipGroup
                  v-model="promotionToEdit.promotionType.discountType"
                  active-class="type-selected text--accent-4"
                  class="ml-3"
                  color="white"
                  column
                  mandatory
                  max="1"
                >
                  <VChip
                    v-for="key in Object.keys(discountTypes)"
                    :key="key"
                    class="circle discount-chip"
                    :value="key"
                  >
                    {{ discountTypes[key] }}
                  </VChip>
                </VChipGroup>
              </div>
              <FoodsSelection
                :foodIds="promotionToEdit.promotionType.foodIds"
                :restaurantPk="promotionToEdit.restaurant.pk"
                @foodsChanged="promotionToEdit.promotionType.foodIds = $event"
              />
            </div>
          </VCol>
        </VRow>
        <VRow v-if="promotionToEdit.typeName === 'informational'">
          <VCol class="mt-2" cols="2">
            <span>Тип платформы</span>
            <span class="red--text">*</span>
          </VCol>
          <VCol class="pb-0">
            <VCheckbox
              v-for="key in Object.keys(targets)"
              :key="key"
              v-model="promotionToEdit.platforms"
              class="checkbox my-1 light-grey-checkbox"
              color="#E43539"
              hide-details
              :label="targets[key]"
              :ripple="false"
              :rules="[(v) => v.length > 0 || 'Поле не заполнено']"
              :value="key"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol class="mt-7" cols="2">
            <span>Позиция</span>
            <span class="red--text">*</span>
          </VCol>
          <VCol cols="6">
            <VTextField
              v-model.number="promotionToEdit.position"
              v-mask="'####'"
              class="mt-5 mb-1"
              dense
              hide-details="auto"
              outlined
              placeholder="0 - 1000"
              :rules="[rules.position]"
              @focusout="checkForPositionMax"
            />
            <span class="text-caption">
              Позиция по умолчанию “0”. Указать можно только целые числа.
            </span>
          </VCol>
        </VRow>
        <VRow>
          <VCol class="mt-7" cols="2">
            <span>Тип иконки</span>
            <span class="red--text">*</span>
          </VCol>
          <VCol cols="6">
            <VSelect
              v-model="promotionToEdit.iconName"
              class="mt-5"
              dense
              item-color="deep-orange"
              item-value="value"
              :items="iconsTypes"
              outlined
              :rules="[rules.required]"
            >
              <template v-slot:selection="{ item }">
                <img
                  alt="icon"
                  class="mr-1"
                  height="24"
                  :src="item.icon"
                />
                <span class="black--text"> {{ item.label }} </span>
              </template>
              <template v-slot:item="{ item }">
                <img
                  alt="label"
                  class="mr-1"
                  height="24"
                  :src="item.icon"
                />
                <span> {{ item.label }} </span>
              </template>
            </VSelect>
          </VCol>
        </VRow>
        <VRow>
          <VCol class="mt-3" cols="2">
            <span>Время действия</span>
            <span class="red--text">*</span>
          </VCol>
          <VCol cols="5">
            <div class="d-flex align-center mb-1">
              <VMenu
                v-model="startedAtMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <VTextField
                    v-model="promotionToEdit.startedAt"
                    v-bind="attrs"
                    class="calendar-input start-date"
                    dense
                    hide-details
                    outlined
                    readonly
                    required
                    v-on="on"
                  />
                </template>
                <VDatePicker
                  v-model="promotionToEdit.startedAt"
                  color="success"
                  locale="ru"
                  @input="startedAtMenu = false"
                />
              </VMenu>
              <VIcon class="ml-1 calendar-icon">mdi-calendar-month</VIcon>
              <span class="ml-2 mr-2">/</span>
              <VMenu
                v-model="endedAtMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                offset-y
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <VTextField
                    v-model="promotionToEdit.endedAt"
                    v-bind="attrs"
                    class="calendar-input end-date"
                    dense
                    hide-details
                    outlined
                    readonly
                    required
                    v-on="on"
                  />
                </template>
                <VDatePicker
                  v-model="promotionToEdit.endedAt"
                  locale="ru"
                  @input="endedAtMenu = false"
                />
              </VMenu>
              <VIcon class="ml-1 calendar-icon">mdi-calendar-month</VIcon>
            </div>
            <span class="text-caption">
              Время действия указывается с 00:00 до 23:59 по умолчанию.
            </span>
          </VCol>
        </VRow>
      </span>
      <VRow>
        <VCol class="d-flex justify-end" cols="10">
          <VBtn
            class="default-btn white--text btn-save mr-3"
            color="#4caf50"
            :disabled="!valid"
            rounded
            @click="savePromotion"
          >
            Сохранить акцию
          </VBtn>
        </VCol>
      </VRow>
    </span>
  </VForm>
</template>

<script>
import { Promotion, promotionTypes, discountTypes } from '@/serializers/promotionSerializer';
import RestaurantSelection from '@/components/RestaurantSelection.vue';
import ImageUpload from '@/components/ImageUpload.vue';
import FoodsSelection from '@/components/FoodsSelection.vue';
import saleIcon from '@/assets/icons/sale-outline.svg';
import giftIcon from '@/assets/icons/gift2.svg';
import freeIcon from '@/assets/icons/free.svg';

export default {
  name: 'PromotionEdit',
  components: {
    FoodsSelection,
    RestaurantSelection,
    ImageUpload,
  },
  props: {
    promotion: {
      type: Promotion,
    },
    targets: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      promotionToEdit: new Promotion(),
      promotionTypes,
      discountTypes,
      iconsTypes: [
        { label: 'Скидка', icon: saleIcon, value: 'discount' },
        { label: 'Бесплатная доставка', icon: freeIcon, value: 'free_delivery' },
        { label: 'Подарок', icon: giftIcon, value: 'gift' },
      ],
      startedAtMenu: false,
      endedAtMenu: false,
      valid: true,
      rules: {
        required: (v) => !!v || 'Поле не заполнено',
        position: (v) => (v !== '' && v >= 0) || 'Поле не заполнено',
      },
    };
  },
  mounted() {
    this.setDefaults();
  },
  methods: {
    setDefaults() {
      if (this.promotion) {
        this.promotionToEdit = this.promotion;
      }
    },
    getMaxRule(max = 24) {
      return (v) => (v && v.length) <= max || 'Превышено количество символов';
    },
    checkForPositionMax() {
      this.promotionToEdit.position = this.promotionToEdit.position > 1000
        ? 1000 : this.promotionToEdit.position;
    },
    savePromotion() {
      if (this.$refs.form.validate()) {
        this.promotionToEdit.platforms = this.promotionToEdit.typeName === 'discount'
          ? Object.keys(this.targets) : this.promotionToEdit.platforms;
        this.$emit('save', this.promotionToEdit);
      }
    },
  },
  watch: {
    promotion() {
      this.setDefaults();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.v-form {
  width: 60%;
  min-width: 800px;
}

.type-selected {
  color: white !important;
  background: $default_green_color !important;
}

.calendar-icon {
  color: $default_dark_gray_color;
}

.circle{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.preview_image-enter-active,
.preview_image-leave-active{
  transition: all .3s;
}

.preview_image-enter,
.preview_image-leave-to{
  filter:blur(5px);
  height:0;
}

::v-deep {
  .v-text-field__details {
    display: inline;
  }
  .v-text-field__slot {
    caret-color: #4D4F67;
  }
  .v-input__slot {
    margin: 0px;
    color: #EE6002;
  }
  .v-label {
    color: black;
  }
  .promo-description-ru, .promo-description-kz {
    .v-messages__message {
      padding-top: 5px;
    }
  }
  .v-messages__message {
    color: #EE6002 !important;
    caret-color: #EE6002 !important;
  }
  .checkbox {
    .error--text {
      color: #EE6002 !important;
      caret-color: #EE6002 !important;
    }
  }
  .light-grey-checkbox .mdi-checkbox-blank-outline {
    color: #E6E6E9;
  }
}
</style>
