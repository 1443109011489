<template>
  <VProgressLinear
    v-show="showProgress"
    :class="classes"
    :color="color"
    query
    :value="progress"
  />
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'green',
    },
    classes: {
      type: String,
      default: 'my-2',
    },
  },
  computed: {
    showProgress() {
      return this.progress > 0 && this.progress < 100;
    },
  },
};
</script>
