<template>
  <div v-show="showProgress" class="text-center">
    <VProgressCircular
      class="position"
      :color="color"
      :size="size"
      :value="progress"
      :width="width"
    />
    {{ progress }}%
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: 'red',
    },
    size: {
      type: [Number, String],
      default: 40,
    },
    width: {
      type: [Number, String],
      default: 3,
    },
  },
  computed: {
    showProgress() {
      return this.progress > 0 && this.progress <= 100;
    },
  },
};
</script>

<style lang="scss" scoped>
.position {
  position: relative;
  left: 0;
}
</style>
