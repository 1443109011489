<template>
  <div>
    <ProgressCircle v-if="showProgress" :progress="progress" />
    <div
      v-if="showProgress ? progress === 0 : !showProgress"
      class="d-flex flex-column justify-center align-center"
    >
      <VBtn
        class="white--text mb-0 upload-button"
        :color="color"
        elevation="0"
        fab
        small
      >
        <div>
          <VIcon v-if="!slotIcon">
            mdi-cloud-upload
          </VIcon>
          <slot v-else name="icon" />
        </div>
        <VTooltip
          v-model="showTooltip"
          color="error"
          min-width="230"
          nudge-right="115"
          nudge-top="10"
          top
        >
          <template v-slot:activator="{ attrs }">
            <input
              ref="file"
              v-bind="attrs"
              accept="image/*"
              style="border: 1px solid black"
              type="file"
              @change="fileSelected"
            >
          </template>
          <span>
            Размер файла больше 200 кб {{
              ratioRequired
                ? ` или соотношение сторон не подпадает под ${ratio}`
                : ''
            }}
          </span>
        </VTooltip>
      </VBtn>
      <div v-show="text" class="section-caption text-center">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
import { getPresignedLink, uploadImage } from '@/api/api';
import { imageValidator } from '@/validators/imageValidator';
import ProgressCircle from '@/components/ProgressCircle.vue';

export default {
  name: 'UploadButton',
  components: { ProgressCircle },
  props: {
    color: {
      type: String,
      default: 'green',
    },
    imageType: {
      type: String,
      required: true,
    },
    ratio: {
      type: String,
      default: '1:2',
    },
    ratioRequired: {
      type: Boolean,
      default: false,
    },
    slotIcon: {
      type: Boolean,
      default: false,
    },
    showProgress: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
  },
  emits: ['progress', 'image-uploaded'],
  data() {
    return {
      showTooltip: false,
      aspectRatioPassed: false,
      progress: 0,
    };
  },
  methods: {
    fileSelected(event) {
      this.showTooltip = false;
      let imageLink;
      const file = event.target.files[0];
      imageValidator(URL.createObjectURL(event.target.files[0]), this.ratioRequired, this.ratio)
        .then(() => {
          this.uploadImage(file, imageLink);
        }).catch(
          () => this.activateTooltip(),
        );
    },
    async uploadImage(file, link) {
      let imageLink = link;
      const bites = 1024;
      const fileSizeKib = file.size / bites;
      if (fileSizeKib.toFixed(0) <= 200) {
        await getPresignedLink(this.imageType)
          .then((result) => {
            imageLink = result;
          });
        imageLink.setFile(file);
        const config = {
          onUploadProgress: (progressEvent) => {
            this.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.$emit('progress', this.progress);
          },
        };
        uploadImage(imageLink, config)
          .then(() => {
            this.$emit('image-uploaded', imageLink.imageURL);
          });
      } else {
        this.activateTooltip();
      }
    },
    activateTooltip() {
      this.showTooltip = true;
      setTimeout(() => {
        this.showTooltip = false;
      }, 4000);
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/mixin.scss";
@import "src/assets/scss/main.scss";

@keyframes appearance {
  from { transform: scale(.5) rotate(-15deg); }
  50%{ transform: scale(1.05) rotate(15deg); }
  to { transform: scale(1) rotate(0deg); }
}
.upload-button {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.upload-button input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}
.section-caption {
  line-height: 16px;
}
</style>
