<template>
  <VCol :cols="cols">
    <Transition name="image">
      <img
        v-if="chosenImage"
        alt="preview_image"
        class="promotion-image__placeholder"
        :class="additionalImageClass"
        :src="chosenImage"
      />
    </Transition>
  </VCol>
</template>

<script>
export default {
  props: {
    cols: {
      type: [String, Number],
      default: 12,
    },
    chosenImage: {
      type: String,
      default: '',
    },
    additionalImageClass: {
      type: String,
      default: 'image',
    },
  },
};
</script>

<style lang="scss" scoped>

.image {
  max-width: 100%;
  height: 220px;
}

.small-image {
  width: 5vw;
}

.promotion-image__placeholder {
  object-fit: contain;
  width: 100%;
}

.image-enter,
.image-leave-to {
  height:0;
}

.image-enter-active,
.image-leave-active {
  transition: height .4s;
}
</style>
