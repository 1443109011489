<template>
  <VCol
    class="d-flex align-center"
    :class="{ 'pa-0 ml-0': dense }"
    :cols="wrapperCols"
  >
    <VCol
      v-if="containsTitle"
      class="image-title-wrapper"
      :cols="leftCol"
    >
      <span class="input-title image-upload-title">{{ title }}</span>
    </VCol>
    <VCol
      class="py-0"
      :class="[!allowPreview ? 'd-flex flex-wrap' : '', { 'px-0': dense }]"
      :cols="12 - leftCol"
    >
      <ImagePreview
        v-if="allowPreview && !imageDown && validImage"
        :chosen-image="chosenImage"
        class="image-wrapper"
        :class="disableXPaddings"
      />
      <ImagePreview
        v-if="imageLeft && validImage"
        additional-image-class="small-image"
        :chosen-image="chosenImage"
        class="mr-4 d-flex justify-center"
        :cols="imageCol"
      />
      <VCol
        class="d-flex flex-wrap align-center"
        :class="[disableXPaddings, { 'py-0': dense }]"
        :cols="imageLeft || imageRight ? 8 : 12"
      >
        <VTextField
          v-model="chosenImage"
          class="pr-3"
          :class="!fullWidth ? 'w80' : 'w65'"
          color="success"
          dense
          hide-details
          outlined
          placeholder="Ссылка или загрузите"
          :rules="required ? [(value) => !!value.length || 'Загрузите изображение'] : []"
        />
        <UploadButton
          ref="fileSelection"
          class="upload-btn"
          :color="buttonColor"
          :image-type="imageType"
          :ratio="ratio"
          :ratio-required="ratioRequired"
          @image-uploaded="chosenImage = $event"
          @progress="uploadProgress = $event"
        />
        <span class="input-description pt-1 pr-10">
          {{ description }}
        </span>
        <ProgressBar :progress="uploadProgress" />
      </VCol>
      <ImagePreview
        v-if="imageRight && validImage"
        additional-image-class="small-image"
        :chosen-image="chosenImage"
        class="ml-1 d-flex justify-center"
        :cols="imageCol"
      />
      <ImagePreview
        v-if="imageDown && validImage"
        :chosen-image="chosenImage"
        class="image-wrapper"
        :class="disableXPaddings"
        :cols="12"
      />
    </VCol>
  </VCol>
</template>

<script>
import { imageValidator } from '@/validators/imageValidator';
import UploadButton from '@/components/UploadButton.vue';
import ImagePreview from '@/components/ImagePreview.vue';
import ProgressBar from '@/components/ProgressBar.vue';

export default {
  props: {
    wrapperCols: {
      type: [Number, String],
      default: 12,
    },
    title: {
      type: String,
      default: 'Изображение',
    },
    image: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    allowPreview: {
      type: Boolean,
      default: true,
    },
    imageLeft: {
      type: Boolean,
      default: false,
    },
    imageRight: {
      type: Boolean,
      default: false,
    },
    imageDown: {
      type: Boolean,
      default: false,
    },
    imageCol: {
      type: [String, Number],
      default: 3,
    },
    description: {
      type: String,
      default: 'Скопируйте ссылку на месторасположение изображения акции,либо загрузите изображение в формате PNG, SVG размером не более 58x58px',
    },
    buttonColor: {
      type: String,
      default: 'green',
    },
    imageType: {
      type: String,
      required: true,
    },
    disablePadding: {
      type: Boolean,
      default: false,
    },
    leftCol: {
      type: [Number, String],
      default: 3,
    },
    containsTitle: {
      type: Boolean,
      default: true,
    },
    ratio: {
      type: String,
      default: '1:2',
    },
    ratioRequired: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UploadButton,
    ProgressBar,
    ImagePreview,
  },
  emits: ['update'],
  data() {
    return {
      chosenImage: this.image,
      uploadProgress: 0,
      validImage: true,
    };
  },
  computed: {
    disableXPaddings() {
      return this.disablePadding ? 'px-0' : '';
    },
  },
  watch: {
    image(newImage) {
      this.chosenImage = newImage;
    },
    chosenImage(newPath, oldPath) {
      if (newPath !== oldPath && newPath != null) {
        if (newPath.length > 0) {
          const trimmed = newPath.trim();
          imageValidator(trimmed, this.ratioRequired, this.ratio)
            .then(() => {
              this.$emit('update', { logo: trimmed });
              this.validImage = true;
              this.$refs.fileSelection.showTooltip = false;
            })
            .catch(() => {
              this.validImage = false;
              this.$refs.fileSelection.showTooltip = true;
            });
        } else {
          this.$emit('update', { logo: newPath });
          this.validImage = false;
          this.$refs.fileSelection.showTooltip = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.v-btn--fab.v-size--small {
  height: 40px !important;
  width: 40px !important;
}

.w65{
  max-width: 65%;
}

.w29{
  width: 29%;
}

.w80{
  width: 80%;
}

.w100{
  width: 100% !important;
}
</style>
