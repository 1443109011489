<template>
  <VContainer class="pa-0 ma-0">
    <VChipGroup
      v-model="discountFoodSelection"
      active-class="type-selected"
      class="mb-1"
      mandatory
      return-object
    >
      <VChip
        v-for="(name, type) in discountFoodSelectionTypes"
        :key="type"
        class="justify-center py-2"
        :value="type"
      >
        <span class="ma-5">{{ name }}</span>
      </VChip>
    </VChipGroup>
    <div
      v-if="discountFoodSelection === 'select'"
      class="d-flex flex-column"
    >
      <span class="py-2">Категории и блюда</span>
      <VTreeview
        ref="foodSelection"
        v-model="selectedItems"
        class="food-selection"
        item-children="foods"
        item-key="oid"
        item-text="title"
        :items="foodTypes"
        open-on-click
        return-object
        selectable
        selected-color="#E53935"
      />
    </div>
  </VContainer>
</template>

<script>
import { fetchExtendedRestaurant } from '@/api/api';

export default {
  name: 'FoodsSelection',
  props: {
    restaurantPk: [String],
    foodIds: [Array],
  },
  emits: ['foodsChanged'],
  mounted() {
    this.fetchFoods();
  },
  data() {
    return {
      discountFoodSelectionTypes: {
        all: 'Все меню',
        select: 'Блюдо / блюда',
      },
      discountFoodSelection: 'select',
      foodTypes: [],
      selectedItems: [],
      lastSelectedItems: [],
    };
  },
  methods: {
    fetchFoods() {
      fetchExtendedRestaurant(this.restaurantPk).then((extRest) => {
        this.foodTypes = extRest.foodTypes.filter((foodType) => foodType.foods.length > 0);
        this.updateItems();
      });
    },
    updateItems() {
      this.$nextTick().then(() => {
        const newSelectedItems = [];
        const itemsDict = {};
        this.foodIds.forEach((oid) => {
          this.foodTypes.forEach((foodType) => {
            foodType.foods.forEach((food) => {
              if (food.oid === oid) {
                itemsDict[oid] = food;
              }
            });
          });
        });
        Object.keys(itemsDict).forEach((key) => newSelectedItems.push(itemsDict[key]));
        this.selectedItems = newSelectedItems;
      });
      if (this.discountFoodSelection === 'select') {
        this.lastSelectedItems = JSON.parse(JSON.stringify(this.selectedItems));
      }
    },
    itemSelected() {
      const itemsIds = [...new Set(this.selectedItems.map((food) => food.oid))];
      this.$emit('foodsChanged', itemsIds);
    },
  },
  watch: {
    restaurantPk() {
      this.fetchFoods();
    },
    foodIds() {
      this.updateItems();
    },
    discountFoodSelection() {
      if (this.discountFoodSelection === 'all') {
        this.selectedItems = this.foodTypes.reduce((pr, cur) => pr.concat(cur.foods), []);
      } else {
        this.selectedItems = this.lastSelectedItems;
      }
    },
    selectedItems(newSelection, oldSelection) {
      if (JSON.stringify(newSelection) !== JSON.stringify(oldSelection) && newSelection.length) {
        this.itemSelected();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

.type-selected {
  color: white !important;
  background: $default_green_color !important;
}
.food-selection {
  width: 600px;
  ::v-deep .v-treeview-node__root {
    background: #FFFFFF;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.24), 0 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    margin: 10px 0;
  }
  ::v-deep .v-treeview-node__level {
    width: 0;
  }
  ::v-deep .v-treeview-node__children .v-treeview-node__root {
    margin-left: 45px;
    background: #F5F5F5;
  }
  ::v-deep .theme--light.v-icon:focus::after {
    opacity: 0;
  }
}
</style>
